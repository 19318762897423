import data from "../data/data.json"
import { ActualitySection } from "../components/ActualitySection";

export const Actuality = () => {
    const actualities = data.actualities.map((section, index) => (
        <ActualitySection 
        title_section={section.title}
        date={section.date}
        subtitle={section.subtitle}
        image={section.image}
        description={section.description}
        />
    ));
    return(
        <div>
            <header style={{width:"100%",height:"100px"}}></header>
            <main> {actualities} </main>
        </div>
    );
}