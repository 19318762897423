import "../style/product.css"
import { useEffect,useState } from "react";

type product = {
    name : string;
    image:string;
    image_little:string;
    description : string;
    ratio?:string;
    ratio_little?:string;
}

export const Product:React.FC<product> = ({name,image,image_little,description,ratio="2.5",ratio_little="1.2"}) => {
    const [dynamicImage, setDynamicImage] = useState<string>(window.innerWidth<800?image_little: image);
    const [dynamicRatio, setDynamicRatio] = useState<string>(window.innerWidth<800?ratio_little:ratio);
    useEffect(() => {
        const handleResize = () => {
            setDynamicImage(window.innerWidth<800?image_little:image);
            setDynamicRatio(window.innerWidth<800?ratio_little:ratio);

            if(ratio_little!="1.2"){console.log(ratio_little)}
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <div className="container">
            <div className="left">
                <h1 className="title">{name}</h1>
                <img style={{aspectRatio:dynamicRatio}} className="image" src={dynamicImage}/>
            </div>
            <div className="right">
                <p className="description">{description}</p>
            </div>
        </div>
    )
}