import data from "../data/data.json"
import "../style/presentation.css"
import { TextSection } from "../components/TextSection"

export const Home = () => {
    const alignments: ("center" | "left" | 'right')[] = ["center","left", 'right'];
    const textsectionComponents = data.dairy_presentation.map((section, index) => (
        <TextSection
            key={index}
            title_section={section.section_title}
            image={section.image}
            text={section.text}
            alignment={alignments[window.innerWidth < 700 ? 0 : index%alignments.length]}
            backgroundColor="#2B7A78"
            titleColor="#FEFFFF"
            textColor="#DEF2F1"
        />
    ));

    // Access the image path at the random index 58, 175, 169
    const randomBanner = data.banners[Math.floor(Math.random() * data.banners.length)];
    return(
        <div>
            <header style={{backgroundColor:"#0",width:"100%",height:"100px"}}></header>
            <img src={randomBanner.image} style={{width:"100%",aspectRatio:randomBanner.ratio,borderRadius:"20px"}}/>
            <h1 style={{color:"#DEF2F1",position:"absolute",top:"100px",fontSize:"5vw",width:"60vw",left:"30%",right:"70%"}}>Laiterie du Vieux Ksar</h1>
            <main> {textsectionComponents} </main>
        </div>
    )
}