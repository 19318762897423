import './App.css';
import { Routes,Route, NavLink } from 'react-router-dom';
import { Home } from './screens/Home';
import { Products } from './screens/Products';
import { Contact } from './screens/Contact';
import { Actuality } from './screens/Actuality';


function App() {
  const setScrollPosition = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div style={{backgroundColor:"#3AAFA9"}}>
      <nav className='button_container'>
        <NavLink onClick={() => setScrollPosition()} className={({ isActive }) => (isActive ? 'button selected' : 'button')} to="/">Qui Sommes Nous ?</NavLink>
        <NavLink onClick={() => setScrollPosition()} className={({ isActive }) => (isActive ? 'button selected' : 'button')} to="/Products"> Nos Produits</NavLink>
        <NavLink onClick={() => setScrollPosition()} className={({ isActive }) => (isActive ? 'button selected' : 'button')} to="/Actuality"> Actualités</NavLink>
        <NavLink onClick={() => setScrollPosition()} className={({ isActive }) => (isActive ? 'button selected' : 'button')} to="/Contact"> Contact</NavLink>
      </nav>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/Products' element={<Products/>}/>
        <Route path='/Actuality' element={<Actuality/>}/>
        <Route path='/Contact' element={<Contact/>}/>
      </Routes>
    </div>
  );
}

export default App;
