import data from "../data/data.json"
import { Link_contact } from "../components/Link_contact"
import { useEffect,useState } from "react";

export const Contact = () => {
    const contactComponents = data.contact.map((contact, index) => (
        <Link_contact
            key={index}
            display_link={contact.display_link}
            icon={contact.icon}
            link={contact.link}
            index={index}
        />
    ));
    const randomBanner = data.banners_contacts[Math.floor(Math.random() * data.banners_contacts.length)];

    const [dynamicMarginLeft, setDynamicMarginLeft] = useState<string>(window.innerWidth<800?("10%"): ("50%"));
    const marginLeftButton = dynamicMarginLeft === "50%" ? "60%" : "30%";
    const [dynamicMarginRight, setDynamicMarginRight] = useState <string>(window.innerWidth<800?("10%"):("10%"));
    const [dynamicWidth, setDynamicWidth] = useState<string>(window.innerWidth<800?("80%"):("40%"));
    const [dynamicMaxFontSize, SetDynamicMaxFontSize] = useState<string>(window.innerWidth<1500?("3vw"):("45px"));

    useEffect(() => {
        const handleResize = () => {
            setDynamicMarginLeft(window.innerWidth<800?("10%"): ("50%"));
            setDynamicMarginRight(window.innerWidth<800?("10%"):("10%"));
            setDynamicWidth(window.innerWidth<800?("80%"):("40%"));
            SetDynamicMaxFontSize(window.innerWidth<1500?("3vw"):("45px"));
            console.log(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return(
        <div>
            <header style={{backgroundColor:"#0",width:"100%",height:"100px"}}></header>
            <img src={randomBanner.image} style={{marginLeft:"10%",marginRight:"10%",width:"80%",aspectRatio:randomBanner.ratio,borderRadius:"20px"}}/>
            {contactComponents}
            <form>
                <div>
                    <label style={{width:dynamicWidth,marginLeft:dynamicMarginLeft,marginRight:dynamicMarginRight,marginTop:"50px"}} htmlFor="email">Votre email</label><br></br>
                    <input style={{width:dynamicWidth,marginLeft:dynamicMarginLeft,marginRight:dynamicMarginRight,borderRadius:"10px"}}type="email" id="email" placeholder="email..."/>
                    <label style={{width:dynamicWidth,marginLeft:dynamicMarginLeft,marginRight:dynamicMarginRight,marginTop:"50px"}} htmlFor="message">Votre message</label><br></br>
                    <textarea style={{width:dynamicWidth,marginLeft:dynamicMarginLeft,marginRight:dynamicMarginRight,borderRadius:"10px",resize:"none"}} rows={10} id="message" placeholder="message..."/>
                
                    <input style={{padding:"3%",width:`calc(${dynamicWidth} / 2)`,maxWidth:"300px",maxHeight:"150px",marginLeft:marginLeftButton,marginRight:"20%",borderRadius:"10px", cursor: "pointer",fontSize:dynamicMaxFontSize,fontWeight:"bold",backgroundColor:"#2B7A78"}} onMouseOver={(e) => (e.target as HTMLInputElement).style.backgroundColor = "#1E524F"} onMouseOut={(e) => (e.target as HTMLInputElement).style.backgroundColor = "#2B7A78"} type="submit"/>
                </div>
            </form>
        </div>
    )
}