import "../style/actuality.css"
type actuality_section = {
    title_section : string;
    date: string;
    subtitle:string;
    description?:string;
    image:string;
}

export const ActualitySection:React.FC<actuality_section> = ({title_section,date,subtitle,description,image}) => {
    return(
        <div className="container_actuality">
            <div className="left_actuality">
                {date && <em className="date_actuality">- {date} :</em>}
                {!date && <em className="date_actuality"></em>}
                <h1 className="title_actuality">{title_section}</h1>
                <img className="image_actuality" src={image}/>

                <div className="right_actuality_small">
                    <h3 style={{fontSize:"15px"}}>{subtitle}</h3>
                    <p className="description_actuality">{description}</p>
                </div>
            </div>
            <div className="right_actuality">
                <h3 style={{fontSize:"15px",color:"white"}}>{subtitle}</h3>
                <p className="description_actuality">{description}</p>
            </div>
        </div>
    );
}
