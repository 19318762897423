import { useEffect,useState } from "react";
import "../style/presentation.css"

type text_section = {
    title_section : string;
    text : string;
    image:string;
    alignment?: 'left' | 'center' | 'right'; // Optional alignment prop
    textColor?: string; // Optional text color prop
    titleColor?:string;
    backgroundColor?:string;
}

export const TextSection:React.FC<text_section> = ({title_section,text,image,alignment,textColor,titleColor,backgroundColor}) => {
    const [dynamicAlignment, setDynamicAlignment] = useState<'left' | 'center' | 'right'>(alignment || 'left');

    useEffect(() => {
        const handleResize = () => {
            setDynamicAlignment(window.innerWidth < 700 ? 'center' : alignment || 'left');
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [alignment]);

    const sectionStyles: React.CSSProperties = {
        display: 'flex', // Use flexbox layout
        flexDirection: dynamicAlignment === 'center' ? 'column' : 'row',
        alignItems:"center",
        marginLeft: dynamicAlignment === 'right' ? '50px' : "5px",
        marginRight: dynamicAlignment === "left" ? "50px" : "5px",
        borderRadius:"10px",
        textAlign: dynamicAlignment, // Set text alignment based on the alignment prop
        color: textColor, // Set text color based on the textColor prop
        backgroundColor:backgroundColor
    };
    const titleStyles: React.CSSProperties = {
        color:titleColor,
        textAlign: dynamicAlignment,
        marginRight:"40px",
        marginLeft:"40px",
        textDecoration:"underline",
        fontSize:"30px",
    }

    return(
        <div>
            <div>
                <h2 className="section h2" style={titleStyles}>{title_section}</h2>
            </div>
            <section style={sectionStyles}>
                <div style={{ display: 'flex', flexDirection: dynamicAlignment === 'left' ? 'row' : dynamicAlignment === 'right' ? 'row-reverse' : 'column', alignItems: 'center' }}>
                    <p className="section p">{text}</p>
                    <img className={`section img`} src={image} alt={title_section} />
                </div>
            </section>
        </div>
    )
}