import "../style/link.css"
import { useState,useEffect } from "react"

type link = {
    display_link : string;
    icon:string;
    link : string;
    index : number;
}

export const Link_contact:React.FC<link> = ({display_link,icon,link,index}) => {
    const [dynamicBottom, setDynamicBottom] = useState<string>(window.innerWidth<800?(index * 45 + 'px'): ((index * 5 + (index+1)* ((10 / window.innerWidth) * 100)) + 'vw'));
    useEffect(() => {
        const handleResize = () => {
            setDynamicBottom(window.innerWidth<800?(index * 45 + 'px'): ((index * 5 + (index+1)* ((10 / window.innerWidth) * 100)) + 'vw'));
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <div style={{position:"fixed",display:"flex",bottom:dynamicBottom,alignItems:"center",left:'10px'}}>
            <img style={{aspectRatio:"1/1",width:"5vw",minWidth:"40px"}} src={icon}/>
            <a className="link" href={link}>{display_link}</a>
        </div>
    )
}