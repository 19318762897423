import { Product } from "../components/Product"
import data from "../data/data.json"

export const Products = () => {
    const productComponents = data.products.map((product, index) => (
        <Product
            key={index}
            name={product.name}
            image={product.image}
            image_little={product.image_little?product.image_little:product.image}
            description={product.description}
            ratio={product.ratio?product.ratio:"2.5"}
            ratio_little={product.ratio_little?product.ratio_little:"1.2"}
        />
    ));
    // Access the image path at the random index 58, 175, 169
    const randomBanner = data.banners_products[Math.floor(Math.random() * data.banners_products.length)];

    return(
        <div>
            <header style={{width:"100%",height:"100px"}}></header>
            <img src={randomBanner.image} style={{marginLeft:"2.5%",marginRight:"2.5%",width:"95%",aspectRatio:randomBanner.ratio,borderRadius:"20px"}}/>
            <main>
                {productComponents}
            </main>
            <footer style={{backgroundColor:"#0",width:"100%",height:"10px"}}></footer>
        </div>
    )
}